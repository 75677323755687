import React, { useCallback, useEffect, useMemo, useState } from 'react'
import './VoucherItem.scss'
import moment from 'moment'
import 'moment/locale/ko'
import { useHistory } from 'react-router-dom'
import { useVoucherContext } from '../../../../context/Common/voucher'
import { useAccountContext } from '../../../../context/Common/account'
import { useModalContext } from '../../../../context/Common/modal'
import BottomSheet from '../../../Voucher/BottomSheet/BottomSheet'
import Minus from '../../../../assets/icon/system/voucherActiveMinus.png'
import DeactiveMinus from '../../../../assets/icon/system/voucherDeactiveMinus.png'
import Plus from '../../../../assets/icon/system/voucherActivePlus.png'
import DeactivePlus from '../../../../assets/icon/system/voucherDeactivePlus.png'
import BottomButton from '../../../Voucher/BottomSheet/BottomButton'
import { postRefundRequest } from '../../../../lib/voucher'
import { usePartnerContext } from '../../../../context/Common/partner'
import { useMyPageContext } from '../../../../context/Booking/myPage'

const PARTNER_TYPE = {
	tablemanager: 'MYTABLE',
	shinhan: 'SHINHANPLAY',
	skt: 'SKT'
}

export default function VoucherItem(props) {
	moment().locale('ko')
	const history = useHistory()
	const { voucher, ticket } = props
	const { setVoucherMalls, setVoucherId } = useVoucherContext()
	const { voucherList, getMyList } = useMyPageContext()
	const { user } = useAccountContext()
	const { alertOpen } = useModalContext()
	const { apiKey, partnerInfo, partnerName } = usePartnerContext()
	const isRefundable = useMemo(
		() =>
			voucher.points.filter((item) => item.refundable && item.externalId === (ticket ?? voucher.products[0]).externalId)
				.length > 0,
		[voucher, ticket]
	)
	const [refundCount, setRefundCount] = useState({})
	const [totalRefundPrice, setTotalRefundPrice] = useState(0)
	const [isOpenRefundSheet, setIsOpenRefundSheet] = useState(false)
	const [isOpenReservNote, setIsOpenReservNote] = useState(false)
	const [currentReservNoteDepth, setCurrentReservNoteDepth] = useState(0)
	const [totalData, setTotalData] = useState()

	const refundableVoucherList = (id) => {
		return voucher.points?.filter(item => item.refundable && id === item.externalId)
	}

	const getVoucherInfo = useCallback(() => {
		if (!voucher)
			return

		let subTitle, totalAmount, refundableList, refundableCounter

		if (ticket) {
			// 메뉴권
			const hasRefundableItem = refundableVoucherList(ticket.externalId)?.length > 0
			subTitle = ticket.name
			totalAmount = `잔여수량 : ${ticket.leftPurchaseCount}장`
			refundableList = hasRefundableItem
				? [{
					name: ticket.name,
					count: refundableVoucherList(ticket.externalId)?.length,
				}]
				: [null]
			refundableCounter = hasRefundableItem
				? [ticket]
				: [null]
		} else {
			// 금액권
			const productNumber = voucher.products.map(product => ({
				id: product.id,
				amount: product.amount,
				purchaseCount: product.purchaseCount
			}))

			subTitle = productNumber.map((product) => {
				let thousandUnitValue = parseInt(product.amount / 1000, 10)
				if (thousandUnitValue % 10 > 0) {
					if (thousandUnitValue > 10)
						return `${parseInt(thousandUnitValue / 10)}만${thousandUnitValue % 10}천원권`
					return `${thousandUnitValue % 10}천원권`
				}
				return `${thousandUnitValue / 10}만원권`
			}).join(' / ')
			totalAmount = `잔여금액 : ${voucher.totalLeftAmount.toLocaleString()}원`
			refundableList = voucher.products?.map((item) => {
				if (refundableVoucherList(item.externalId).length > 0) {
					return {
						name: item.name,
						count: refundableVoucherList(item.externalId)?.length,
					}
				}
				return null
			})
			refundableCounter = voucher.products?.map((item) => {
				if (refundableVoucherList(item.externalId).length > 0)
					return item

				return null
			})
		}

		setTotalData({ subTitle, totalAmount, refundableList, refundableCounter })
	}, [voucher])

	const getQueryParams = () => history.location.search
		.replace('?', '')
		.split('&')
		.reduce((r, e) => {
			const key = e.split('=')[0]
			const value = decodeURIComponent(e.split('=')[1])
			return {
				...r,
				[key]: value
			}
		}, {})

	function MallImage() {
		const imageStyle = {
			background: `url('${voucher.service.thumbnail}')`,
			backgroundPosition: '50% 50%',
			backgroundSize: 'cover'
		}
		return (
			<div className="mall-image" style={imageStyle}>
				{/* {!isRefundable && <div className="badge b3">환불 불가</div>} */}
			</div>
		)
	}
	function popMalls() {
		setVoucherId(voucher.products[0].externalId)
		setVoucherMalls(voucher.brand.malls)
		const voucherInfo = {
			theyeyakUserToken: sessionStorage.getItem('authorization') || user.token,
			voucherPointId: voucher.inquiryId,
			productId: ticket?.id ?? voucher.id,
		}
		if (ticket)
			voucherInfo.voucherOrderItemId = ticket.itemId
		sessionStorage.setItem('voucherData', JSON.stringify(voucherInfo))
		history.push('/booking/voucher/malls')
	}

	const showNonRefundableAlert = (errorKey) => {
		if (errorKey === 'FAILED') {
			// 환불 error = 오류
			alertOpen('일시적인 오류로 환불요청에 실패했습니다.\n다시 시도해주세요.', undefined, '환불이 불가합니다.')
		} else {
			// 환불 error = 환불 불가
			alertOpen('[환불 정책]\n구매 후 14일 이내 : 환불가능\n\n환불 정책에 따라, 선택하신 예약상품권은 환불이 불가합니다.', undefined, '환불이 불가합니다.')
		}
	}
	const clickRefundVoucherHandler = () => {
		const targetVoucher = ticket ?? voucher.products[0]

		const resetRefundCount = () => {
			if (ticket) {
				let init = {}
				init[targetVoucher.externalId] = 0
				setRefundCount(init)
			} else {
				let init = {}
				voucher.products?.forEach((item) => {
					init[item.externalId] = 0
				})
				setRefundCount(init)
			}
			setTotalRefundPrice(0)
		}

		if (isRefundable) {
			resetRefundCount()
			setIsOpenRefundSheet(true)
		} else {
			showNonRefundableAlert(null)
		}
	}

	const clickAgreeReservationNotesHandler = (isAgree) => {
		if (isAgree) {
			const isCurrentNoteLastDepth = currentReservNoteDepth === voucher.note?.length - 1

			if (isCurrentNoteLastDepth) {
				setIsOpenReservNote(false)
				setCurrentReservNoteDepth(0)
				popMalls()
			} else {
				setCurrentReservNoteDepth(prev => prev + 1)
			}
		} else {
			alertOpen('예약 전 동의사항에 모두 동의해야 예약상품권 예약이 가능합니다.', '확인', '다시 예약해주세요.')
			setCurrentReservNoteDepth(0)
			setIsOpenReservNote(false)
		}
	}

	const controlRefundCount = (count, itemObj, maxLength) => {
		if (count === 1) {
			if (refundCount[itemObj.externalId] < maxLength) {
				setRefundCount(prev => ({ ...prev, [itemObj.externalId]: prev[itemObj.externalId] + count }))
				setTotalRefundPrice(prev => prev + itemObj.salePrice)
			}
		} else if (count === -1) {
			if (refundCount[itemObj.externalId] >= 1) {
				setRefundCount(prev => ({ ...prev, [itemObj.externalId]: prev[itemObj.externalId] + count }))
				setTotalRefundPrice(prev => prev - itemObj.salePrice)
			}
		}
	}

	const clickUseVoucherHandler = (e) => {
		e.stopPropagation()
		// 사용하기 버튼 클릭
		if (voucher.note?.length)
			setIsOpenReservNote(true)
		else
			popMalls()
	}

	const submitRefundRequest = async () => {
		// 환불 요청 버튼 클릭
		let refundIdList = []

		if (ticket) {
			refundIdList = refundableVoucherList(ticket.externalId).slice(0, refundCount[ticket.externalId]).map(item => item.id)
		} else {
			Object.keys(refundCount).forEach(select => {
				refundableVoucherList(select).slice(0, refundCount[select]).forEach(item => refundIdList.push(item.id))
			})
		}

		const isPartner = partnerName !== 'tablemanager'

		let throttle
		if (throttle)
			clearTimeout(throttle)

		const postRefund = await postRefundRequest(
			{
				partnerId: 'SKT',
				brandId: voucher.brand?.id,
				custompointIds: refundIdList,
			},
			sessionStorage.getItem('authorization'),
			isPartner
		)
			.then(() => {
				setIsOpenRefundSheet(false)
				alertOpen(
					'예약상품권 환불이 완료되었습니다.\n\n환불 요청 이후 환불 완료 시까지 3~5영업일 정도 소요 될 수 있습니다.',
					'확인',
					'예약상품권 환불 완료'
				)
			})
			.catch((err) => {
				setIsOpenRefundSheet(false)
				showNonRefundableAlert(err.code)
			})
			.finally(() => getMyList({ apiKey, partnerName, partnerInfo }))

		throttle = setTimeout(() => {
			if (postRefund)
				return postRefund()
			return null
		}, 500)
	}

	useEffect(() => {
		const params = getQueryParams()
		if (params.inquiryId && voucherList && voucherList.length > 0) {
			const selectedVoucher = voucherList.find(voucherItem => voucherItem.inquiryId === params.inquiryId)
			const sameSelectedProductItem = selectedVoucher?.products?.find(item => item.externalId === selectedVoucher?.points[0].externalId)
			let pointId = ''
			if (selectedVoucher?.points[0].amount > 0)
				pointId = selectedVoucher?.points[0].id

			setVoucherId(sameSelectedProductItem?.externalId)
			setVoucherMalls(selectedVoucher?.brand.malls)

			if (ticket) {
				const voucherData = {
					theyeyakUserToken: sessionStorage.getItem('authorization') || user.token,
					voucherPointId: pointId,
					voucherOrderItemId: sameSelectedProductItem.itemId,
					productId: sameSelectedProductItem.id,
				}
				sessionStorage.setItem('voucherData', JSON.stringify(voucherData))
			}

			history.push(`/booking/voucher/malls${`#${params.inquiryId}` || null}`)
		}
	}, [voucherList])

	useEffect(() => getVoucherInfo(), [getVoucherInfo])

	if (!voucher || !totalData)
		return null

	return (
		<div className="voucher-item-container">
			<div className="mall-container">
				<div>
					<MallImage></MallImage>
				</div>
				<div className="mall-right-area">
					<div className='mall-content'>
						<div className="mall-name t5">{voucher.brand.name}</div>
						<div className="mall-info voucher-info b2">{totalData.subTitle}</div>
						<div className="mall-info people-info b2">{totalData.totalAmount}</div>
						<div className="mall-info date-info b2">유효기간 : {moment(voucher.points[0].expiryDate).format('YYYY.MM.DD')}</div>
					</div>
					<div className='mall-action'>
						<div className="use-button b1" onClick={(e) => clickUseVoucherHandler(e)}>
						사용하기
						</div>
						<div className="use-button b1" onClick={() => clickRefundVoucherHandler()}>
						환불하기
						</div>
					</div>
				</div>
			</div>
			{/* 빕스 예약상품권 - 유의사항 */}
			{isOpenReservNote &&
				<BottomSheet title="예약상품권 사용">
					{
						voucher.note?.length ?
							<section className='voucher-ticket-notes-content'>
								<h3>{voucher.note[currentReservNoteDepth][0]}</h3>
								<p>{voucher.note[currentReservNoteDepth][1]}</p>
							</section>
							: null
					}
					<BottomButton className="button-wrapper ticket-notes-button-wrapper share" cancelFunc={() => clickAgreeReservationNotesHandler(false)} confirmFunc={() => clickAgreeReservationNotesHandler(true)} cancelBtn="동의안함" confirmBtn="동의" />
				</BottomSheet>}
			{/* 빕스 예약상품권 - 환불 */}
			{isOpenRefundSheet && (
				<BottomSheet title="예약상품권 환불">
					<section className="voucher-refund-wrapper">
						<div className="voucher-refund-content">
							{
								<ul>
									<li>
										<h3>환불 가능한 예약상품권 현황</h3>
										{
											totalData.refundableList?.map(
												(item, idx) =>
													item.count > 0 && (
														<div className="voucher-list-wrapper" key={idx}>
															<dl>
																<dt>{item.name}</dt>
																<dd>{item.count}장</dd>
															</dl>
														</div>
													)
											)
										}
									</li>
									<li>
										<h3>환불 요청할 예약상품권</h3>
										{
											totalData.refundableCounter?.map((item) =>
												refundableVoucherList(item.externalId).length > 0 && (
													<div className="voucher-list-wrapper" key={item.itemId}>
														<dl className="voucher-item">
															<dt>{item.name}</dt>
															<dd className="voucher-information">
																<div className="icon-group">
																	<div className="icon-box minus" onClick={() => controlRefundCount(-1, item)}>
																		{refundCount[item.externalId] !== 0
																			? (<img src={Minus} className="icon active" alt="마이너스 버튼" />)
																			: (<img src={DeactiveMinus} className="icon deactive" alt="마이너스 버튼" />)}
																	</div>
																	<span className="value">{refundCount[item.externalId]}</span>
																	<div
																		className="icon-box plus"
																		onClick={() => controlRefundCount(1, item, refundableVoucherList(item.externalId).length)}>
																		{refundCount[item.externalId] < refundableVoucherList(item.externalId).length
																			? (<img src={Plus} className="icon active" alt="플러스 버튼" />)
																			: (<img src={DeactivePlus} className="icon deactive" alt="플러스 버튼" />)}
																	</div>
																</div>
															</dd>
														</dl>
													</div>
												))
										}
									</li>
								</ul>
							}
						</div>
						<div className="voucher-total-purchase-price-wrapper">
							<div className="total-information">
								<span className="total-information-title">환불 예정 금액</span>
								<span className="total-price">{totalRefundPrice.toLocaleString()}원</span>
							</div>
						</div>
					</section>
					<BottomButton
						className="button-wrapper ticket-notes-button-wrapper share"
						cancelFunc={() => setIsOpenRefundSheet(false)}
						confirmFunc={submitRefundRequest}
						cancelBtn="취소"
						confirmBtn="환불요청"
						isDisabledConfirmBtn={totalRefundPrice <= 0}
					/>
				</BottomSheet>
			)
			}
		</div>
	)

}

import { setup } from 'axios-cache-adapter'
import { v4 as uuidv4 } from 'uuid'

// axios 생성
let axios = setup({
	baseURL: process.env.REACT_APP_MYTABLE_LEMON_API_HOST,
  headers: {
  }
})

//
// API caller
//
const _call = async ({
	method = 'get',
	uri,
	headers = {},
	expires = 0,
	body = {},
}) => {
	method = method.toLowerCase()
	if (
		['get', 'delete', 'post', 'patch', 'put', 'options'].indexOf(method) < 0
	) {
		const message = `method is wrong - method: ${method}`
		throw new Error(message)
	}

	expires = Number(expires)
	if (isNaN(expires)) {
		console.warn(
			'expires is assigned 0 (=default value) because type is not number'
		)
		expires = 0
	}

	const request = axios({
		method,
		data: body,
		url: uri,
		cache: { maxAge: expires },
		headers: headers? headers : axios.headers
	})

	return await request.then(async (response) => {
		const data = response.data

		switch (data.code) {
			case 'E203':
				alert("E203: 매장이 없습니다.");
				window.history.back()
				break;
		}

		if (data.code && data.code !== 200 && response.status !== 200)
			throw data

		return data
	})
}

const reqeustOldCancelReservation = async (reservationId, userId) => {
	return _call({
		method: 'POST',
		uri: `/reservations/${reservationId}/cancel`,
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${userId}`,
			'X-Api-Key': 'F1B80501BB7989BB5B15B4934DD268BA88261B8D',
			'X-UUID' : uuidv4()
		}
	})
}

const oldCancelReservation = async (reservationId, cancelToken, userId) => {
	return _call({
		method: 'DELETE',
		uri: `reservations/${reservationId}`,
		body: {
			token: cancelToken,
			isCancel: 1
		},
		headers: {
			'x-api-key': 'F1B80501BB7989BB5B15B4934DD268BA88261B8D',
			Authorization: `Bearer ${userId}`,
			'X-UUID' : uuidv4()
		}
	})
}

const getCardInfo = (userToken) => {
	return _call({
		method: 'GET',
		uri: '/my/card',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${userToken}`,
			'X-Api-Key': 'F1B80501BB7989BB5B15B4934DD268BA88261B8D',
			'X-UUID': uuidv4()
		}
	})
}

class BookingAPI {
	constructor(apiKey, authorization) {
		this.apiKey = apiKey;
		this.authorization = authorization;
		if (this.apiKey) {
			axios = setup({
				baseURL: process.env.REACT_APP_MYTABLE_LEMON_API_HOST,
				headers: {
					'x-api-key': this.apiKey,
					'authorization': this.authorization
				}
			});
		} else {
			//axios = null;
		}

	}
}

class AuthAPI extends BookingAPI {
	requestPhoneCode = async (phone) => {
		return _call({
			method: 'POST',
			uri: 'members/sms',
			body: {
				phone,
				type: 'signup',
			},
		})
	}

	checkPhoneCode = async (phoneToken, phoneCode, name, email) => {
		return _call({
			method: 'POST',
			uri: 'login/guest',
			body: {
				phone_token: phoneToken,
				phone_code: phoneCode,
				name,
				email,
			},
		})
	}
}
class BookingHomeAPI extends BookingAPI {
	getPartnerBanner = async () => {
		return _call({
			method: 'GET',
			uri: 'partners/banners',
			expires: 60 * 1000,
		})
	};
	getMalls = async () => {
		return _call({
			method: 'GET',
			uri: 'partners/malllist',
			expires: 60 * 1000,
		})
	};
	getRegions = async () => {
		return _call({
			method: 'GET',
			uri: 'partners/samsung/regions',
			expires: 60 * 1000,
		})
	};
}

class BookingMallDetailAPI extends BookingAPI {
	getMall = async (mallUri) => {
		return _call({
			method: 'GET',
			uri: `malls/${mallUri}`,
			expires: 60 * 1000,
		})
	};

	getDates = async (mallUri, partySize, voucherOrderItemId) => {
		return _call({
			method: 'GET',
			uri: `malls/${mallUri}/reservation/date?count_people=${partySize}${voucherOrderItemId ? `&voucherOrderItemId=${voucherOrderItemId}` : ''}`,
		})
	};

	getTimes = async (mallUri, partySize, date, voucherOrderItemId) => {
		return _call({
			method: 'GET',
			uri: `malls/${mallUri}/reservation/time?count_people=${partySize}&date_y=${date.year()}&date_m=${date.month() + 1
				}&date_d=${date.date()}${voucherOrderItemId ? `&voucherOrderItemId=${voucherOrderItemId}` : ''}`,
		})
	};

	getCategories = async (mallUri, partySize, date) => {
		let time = date.hour() + Math.floor((date.minute() / 60) * 10000) / 10000
		return _call({
			method: 'GET',
			uri: `malls/${mallUri}/reservation/category?count_people=${partySize}&date_y=${date.year()}&date_m=${date.month() + 1
				}&date_d=${date.date()}&date_h=${time}`,
		})
	};

	makeProvisionalReservation = async (
		mallUri,
		partySize,
		date,
		category_id
	) => {
		let time = date.hour() + Math.floor((date.minute() / 60) * 10000) / 10000
		return _call({
			method: 'POST',
			uri: `malls/${mallUri}/reservation/pre`,
			body: {
				count_people: partySize,
				date_y: date.year(),
				date_m: date.month() + 1,
				date_d: date.date(),
				date_h: time,
				category_id: category_id,
			},
		})
	};

	pretendProvisionalReservation = async (
		provisionalToken
	) => {
		return _call({
			method: 'POST',
			uri: `reservations/start`,
			body: {
				token:provisionalToken
			},
		})
	};


	deleteProvisionalReservation = async (
		mallUri,
		provisionalToken
	) => {
		return _call({
			method: 'DELETE',
			uri: `malls/${mallUri}/reservation/pre`,
			body: {
				token: provisionalToken
			},
		})
	};

	getProvisionalReservationInfo = async (provisonalToken) => {
		return _call({
			method: 'GET',
			headers: {
				authorization: sessionStorage.getItem('authorization')
			},
			uri: `reservations/pre?token=${provisonalToken}`
		})
	}

	sendProvisionalPayment = async (object) => {
		return _call({
			method: 'PUT',
			uri: `reservations/prepayment`,
			body: object,
			headers: {
				authorization: sessionStorage.getItem('authorization')
			}
		})
	}
	checkPhoneAuth = async (phone, provisionalToken) => {
		return _call({
			method: 'POST',
			uri: '/reservations/check',
			body: {
				phone,
				token: provisionalToken
			},
		})
	}

	phoneAuth = async (code, phoneToken, provisionalToken) => {
		return _call({
			method: 'GET',
			uri: `/reservations/sms?phone_code=${code}&phone_token=${phoneToken}&token=${provisionalToken}`,
		})
	}

	createOrder = async (object, userId, isPaymentOrder) => {
		return _call({
			method: 'PUT',
			uri: '/reservations/confirm',
			body: object,
		})
	}

	getPaymentResult = async (orderId) => {
		return _call({
			method: 'GET',
			uri: `reservations/prepayment/status?impUid=${orderId}`
		})
	}
}

class BookingMyPageAPI extends BookingAPI {
	kblogin = async (object) => {
		return _call({
			method: 'POST',
			uri: 'partners/kb/register',
			body: object
		})
	}

	getReservationList = async (userId) => {
		const isPartner = localStorage.getItem('partnerName') !== 'tablemanager'
		return _call({
			method: 'GET',
			uri: 'my/reservation?limit=9999',
			expires: 60 * 1000,
			headers: {
				authorization: isPartner ? userId : `Bearer ${userId}`
			}
		})
	};
	getReservation = async (reservationId, userId) => {
		return _call({
			method: 'GET',
			uri: `reservations/${reservationId}/result`,
			expires: 60 * 1000,
			headers: {
				authorization: userId
			}
		})
	}
	callReservationCancel = async (reservationId, userId) => {
		return _call({
			method: 'POST',
			uri: `reservations/${reservationId}/cancel`,
			headers: {
				authorization: userId
			}
		})
	}
	cancelReservation = async (reservationId, cancelReservationToken, userId) => {
		return _call({
			method: 'DELETE',
			uri: `reservations/${reservationId}`,
			body: {
				token: cancelReservationToken
			},
			headers: {
				authorization: userId
			}
		})
	}
}

class KTNameChangeAPI extends BookingAPI {
	getKTReservation = async (reservationId, token) => {
		return _call({
			method: 'GET',
			uri: `partners/aicc/my/${reservationId}?token=${token}`,
		})
	}

	patchUserName = async (reservationId, token, userName) => {
		return _call({
			method: 'PATCH',
			uri: `partners/aicc/my/${reservationId}`,
			body: {
				token,
				userName
			},
		})
	}
}

export {
	BookingAPI,
	BookingHomeAPI,
	BookingMallDetailAPI,
	BookingMyPageAPI,
	KTNameChangeAPI,
	AuthAPI,
	reqeustOldCancelReservation,
	oldCancelReservation,
	getCardInfo
}

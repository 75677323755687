import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { ReactComponent as Back } from '../../../assets/icon/back.svg'
import { ReactComponent as Close } from '../../../assets/icon/close.svg'
import { ReactComponent as Hamburger } from '../../../assets/icon/hamburger.svg'
import { ReactComponent as Home } from '../../../assets/icon/home.svg'
import { ReactComponent as Locale } from '../../../assets/icon/icon-locale.svg'
import { ReactComponent as Menu } from '../../../assets/icon/icon-menu.svg'
import { ReactComponent as MyPage } from '../../../assets/icon/icon-mypage.svg'
import { ReactComponent as Share } from '../../../assets/icon/share.svg'
import SideMenu from '../SideMenu'
import TheYeyakVoucherMainLogo from '../../../assets/icon/theYeyakVoucherMainLogo.png'
import TrackingAPI from '../../../lib/tracking'
import { ReactComponent as WhiteBack } from '../../../assets/icon/whiteBack.svg'
import { ReactComponent as WhiteHome } from '../../../assets/icon/whiteHome.svg'
import { useAccountContext } from '../../../context/Common/account'
import { useGnbDrawerContext } from '../../../context/Voucher/gnbDrawer'
import { useModalContext } from '../../../context/Common/modal'
import { usePartnerContext } from '../../../context/Common/partner'
import { useBookingContext } from '../../../context/Booking/booking'
import { useReservation } from '../../../store'

export default function Header() {
	const location = useLocation()
	const history = useHistory()
	const search = location.search
	const paramPartnerName = new URLSearchParams(search).get('partner')
	const { partnerName, setPartnerName, headerExists } = usePartnerContext()
	const { shareOpen, shareCallback, setShareCallback } = useModalContext()
	const { setHeaderAction, headerAction, setCloseModal } = useBookingContext()
	const { user } = useAccountContext()
	const { voucherData, getVoucherData } = useReservation()
	const trackingAPI = useMemo(() => new TrackingAPI(user, null), [user])
	const [languageEnabled, setLanguageEnabled] = useState(false)
	const [settings, setSettings] = useState({
		showHeader: false,
		title: '',
		showLogo: false,
		showClose: false,
		showBack: false,
		showHamburger: false,
		showHome: false,
		showMypage: false,
		fixedMode: false,
		twoColMode: false,
		showShare: false,
		showOnlyBack: false,
		rightOnlyCloseButton: false, // twoColMode is required
		isVoucher: false,
		voucherMain: false,
		voucherComplete: false,
		showLocale: false,
	})
	const [showSide, setShowSide] = useState(false)
	const [scrollHeight, setScrollHeight] = useState(0)
	const [languages, setLanguages] = useState([{ code: 'ko', lang: '한국어' }])
	const { isOpenGNBDrawer, setIsOpenGNBDrawer } = useGnbDrawerContext()
	const hasVoucherData = Boolean(sessionStorage.getItem('voucherData'))
	const isPartner = useMemo(() => partnerName !== 'tablemanager', [partnerName])

	useLayoutEffect(() => {
		if (headerAction)
			setHeaderAction(null)
	}, [location.pathname])

	useEffect(() => {
		if (/(pickup\/)\S+(\/order-sheet)/g.test(location.pathname)) {
			changeSettings({
				showHeader: true,
				title: '장바구니',
				showClose: true,
				showBack: true
			})
		} else if (/(pickup\/)\S+\/\S+\/menu-detail\/\S+/g.test(location.pathname)) {
			changeSettings({
				showHeader: true,
				showClose: true,
				showBack: true
			})
		} else if (/(pickup\/)\S+\/order/g.test(location.pathname)) {
			changeSettings({
				showHeader: true,
				title: '픽업 주문하기',
				showClose: true,
				showBack: true
			})
		} else if (/(pickup\/)\S+/g.test(location.pathname)) {
			changeSettings({
				showHeader: true,
				showLogo: true,
				showBack: false
			})
		} else if (/(pickup\/)\S+\/complete\/w+/g.test(location.pathname)) {
			changeSettings({
				showHeader: true,
				title: '주문 완료',
				showClose: true,
				showBack: false
			})
		} else if (/(voucher)\/main/g.test(location.pathname)) {
			changeSettings({
				showHeader: true,
				showLogo: true,
				twoColMode: true,
				voucherMain: true,
				isVoucher: true
			})
			changeDocumentTitle('더예약ㅣ예약에 혜택을 더하다')
		} else if (/(voucher)\/mypage/g.test(location.pathname)) {
			changeSettings({
				showHeader: true,
				showLogo: true,
				twoColMode: true,
				showBack: true,
				voucherMain: true,
				isVoucher: true
			})
		} else if (/(voucher)\/inquiry\/customer/g.test(location.pathname)) {
			changeSettings({
				showHeader: true,
				title: '고객의 소리함',
				twoColMode: true,
				showOnlyBack: true
			})
		} else if (/(voucher)\/inquiry\/partnership/g.test(location.pathname)) {
			changeSettings({
				showHeader: true,
				title: '제휴 및 입점 문의',
				twoColMode: true,
				showOnlyBack: true
			})
		} else if (/(voucher)\/inquiry\/bulkpurchase/g.test(location.pathname)) {
			changeSettings({
				showHeader: true,
				title: '대량 구매 문의',
				twoColMode: true,
				showOnlyBack: true
			})
		} else if (/(voucher)\/inquiry\/live-commerce/g.test(location.pathname)) {
			changeSettings({
				showHeader: true,
				title: '라이브커머스 문의',
				twoColMode: true,
				showOnlyBack: true
			})
		} else if (/(booking)\/main/g.test(location.pathname)) {
			changeSettings({ showMypage: true })
		} else if (/(booking)\/(skt)\/(event)/g.test(location.pathname)) {
			changeSettings({ showHeader: false, showMypage: false })
		} else if (/(booking)\/search/g.test(location.pathname)) {
			changeSettings({ showHeader: false, showMypage: false })
		} else if (/(booking)\/(list)\/(expand)\/(finished)/g.test(location.pathname)) {
			changeSettings({
				showHeader: true,
				title: '방문완료/취소 전체보기',
				showBack: true,
			})
		} else if (/(booking)\/(list)\/(expand)\/(scheduled)/g.test(location.pathname)) {
			changeSettings({
				showHeader: true,
				title: '방문예정 전체보기',
				showBack: true,
			})
		} else if (/(booking)\/(list)\/(expand)\/(voucher)/g.test(location.pathname)) {
			changeSettings({
				showHeader: true,
				title: '예약상품권 전체보기',
				showBack: true,
			})
		} else if (/(booking)\/list/g.test(location.pathname)) {
			const defaultProperty = {
				showHeader: true,
				title: '더예약',
				voucherMain: true,
				isVoucher: true,
				showHome: true,
				showBack: true,
			}
			if (isPartner) {
				changeSettings({
					...defaultProperty,
					title: '더예약',
				})
			} else {
				changeSettings({
					...defaultProperty,
					showLogo: true,
				})
			}
		} else if (/(booking)\/(voucher)\/(malls)/g.test(location.pathname)) {
			changeSettings({
				showHeader: true,
				title: '매장 선택하기',
				showBack: true,
				showHome: true,
			})
		} else if (/(booking)\/detail\/[0-9]+/g.test(location.pathname)) {
			changeSettings({
				showHeader: true,
				showBack: true,
				showHome: false,
				showShare: true,
				title: '예약상세'
			})
		} else if (/(booking)\/(mall-detail)\/\S+\/(gallery)/g.test(location.pathname)) {
			changeSettings({
				showHeader: true,
				showBack: true,
				showHome: true,
				title: '매장 이미지'
			})
		} else if (/(booking)\/(mall-detail)\/\S+\/(facility)/g.test(location.pathname)) {
			changeSettings({
				showHeader: true,
				showBack: true,
				showHome: true,
				title: '편의시설'
			})
		} else if (/(booking)\/(mall-detail)\/\S+\/(menu)/g.test(location.pathname)) {
			changeSettings({
				showHeader: true,
				showBack: true,
				showHome: true,
				title: '전체 메뉴'
			})
		} else if (/(booking)\/(mall-detail)\/\S+/g.test(location.pathname)) {
			// 예약상품권을 통한 진입 구분
			if (hasVoucherData) {
				changeSettings({
					showHeader: true,
					showHome: true,
					showBack: true,
					title: '예약하기',
				})
			} else if (partnerName === 'tablemanager') {
				changeSettings({
					showHeader: true,
					showHome: true,
					title: '예약하기',
					showLocale: true
				})
			} else {
				changeSettings({
					showBack: true,
					fixedMode: true,
					showHome: true
				})
			}
		} else if (/(booking)\/\S+\/(step)\/(incoming)/g.test(location.pathname)) {
			changeSettings({
				showHeader: true,
				showBack: true,
				showHome: true,
			})
		} else if (/(booking)\/\S+\/(step)\/(info)/g.test(location.pathname)) {
			changeSettings({
				showHeader: true,
				showBack: true,
				showHome: true,
				title: '예약하기'
			})
		} else if (/(booking)\/\S+\/(step)\/(category)/g.test(location.pathname)) {
			changeSettings({
				showHeader: true,
				showBack: true,
				showHome: true,
				title: '예약하기'
			})
		} else if (/(booking)\/\S+\/(step)\/(detail-info)/g.test(location.pathname)) {
			changeSettings({
				showHeader: true,
				showBack: true,
				showHome: true,
				title: '예약하기'
			})
		} else if (/(booking)\/\S+\/(step)\/(payment)/g.test(location.pathname)) {
			changeSettings({
				showHeader: true,
				showBack: true,
				showHome: true,
				title: '예약하기'
			})
		} else if (/(booking)\/\S+\/(step)\/(complete)/g.test(location.pathname)) {
			changeSettings({
				showHeader: true,
				showBack: true,
				showShare: true,
				title: '예약 완료'
			})
		} else if (/(booking)\/(step)\/(complete-view)\/[0-9]{1,}$/g.test(location.pathname)) {
			if (hasVoucherData) {
				changeSettings({
					showHeader: true,
					showBack: true,
					showHome: true,
					title: '예약 완료'
				})
			} else {
				changeSettings({
					showHeader: true,
					showBack: true,
					showShare: true,
					title: '예약 완료'
				})
			}
		} else if (/(voucher)\/(step)\/(complete)/g.test(location.pathname)) {
			changeSettings({
				showHeader: true,
				showBack: false,
				twoColMode: true,
				rightOnlyCloseButton: true,
				voucherComplete: true,
				title: '구매 완료'
			})
		} else if (/(voucher)\/(detail)\/\S+/g.test(location.pathname)) {
			changeSettings({
				showHeader: true,
				showBack: false,
				showHome: true,
				voucherComplete: false,
				title: '예약상품권'
			})
		} else if (/(voucher)\/(step)\/(payment)\/\S+/g.test(location.pathname)) {
			changeSettings({
				showHeader: true,
				showBack: false,
				twoColMode: true,
				rightOnlyCloseButton: true,
				title: '예약상품권'
			})
		} else if (/(user)\/(change-name)\/(step)\/(complete)/g.test(location.pathname)) {
			changeSettings({ showHeader: false })
		} else if (/(user)\/(change-name)/g.test(location.pathname)) {
			changeSettings({
				showHeader: true,
				showBack: false,
				title: '예약 이름 변경하기'
			})
		}

		if (window.location.origin.includes('skt')) {
			setPartnerName('skt')
			localStorage.setItem('partnerName', 'skt')
		}
	}, [location, paramPartnerName, setPartnerName])

	const handleScroll = () => {
		const position = window.pageYOffset
		setScrollHeight(position)
	}
	function changeSettings(changedSettings) {
		let {
			showHeader = false,
			title = '',
			showLogo = false,
			showClose = false,
			showBack = false,
			showHamburger = false,
			showHome = false,
			showMypage = false,
			fixedMode = false,
			twoColMode = false,
			showShare = false
		} = changedSettings
		setSettings({ ...changedSettings })
	}

	useEffect(() => getVoucherData(), [])
	useEffect(() => {
		if (voucherData && Object.keys(voucherData).length > 0)
			sessionStorage.setItem('authorization', voucherData.theyeyakUserToken)
	}, [voucherData])

	useEffect(() => {
		window.addEventListener('scroll', handleScroll, { passive: true })
		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [])

	useEffect(() => {
		if (typeof shareCallback === 'boolean')
			setShareCallback(null)

	}, [shareCallback])

	function changeDocumentTitle(title) {
		document.title = title
	}

	function goBack() {
		setHeaderAction('back')
		if (history.length <= 1 && history.location.pathname !== '/voucher/main')
			return history.replace('/voucher/main')
		return history.goBack()
	}

	function goHome() {
		setHeaderAction('home')
		if (/(booking)\/\S+\/(step)\/(payment)/g.test(location.pathname)) {
			setCloseModal(true)
			return
		}
		if (sessionStorage.getItem('referral') === 'shinhanplay') {
			history.replace('/booking/main')
		} else if (location.pathname.indexOf('pickup') > -1) {
			history.replace(`/pickup/${location.pathname.split('/')[2]}`)
		} else if (location.pathname.indexOf('voucher') > -1) {
			history.replace('/voucher/main')
		} else if (location.pathname.indexOf('booking') > -1) {
			// 예약상품권을 통한 진입 구분
			if (hasVoucherData) {
				history.replace('/voucher/main')
			} else if (location.pathname === '/booking/list' && partnerName !== 'tablemanager') {
				history.replace('/booking/main')
			} else {
				if (location.pathname === '/booking/list') {
					history.replace('/voucher/main')
				} else {
					for (let i = history.length; i > 0; i--)
						history.go(-i)
				}
			}
		}
	}

	function goMyReservation() {
		history.push('/booking/list')
	}

	function goMyPage() {
		if (history.location.pathname === '/booking/list') {
			window.location.reload()
		} else {
			history.push('/booking/list')
			if (user)
				trackingAPI.mypage('main에서 mypage 진입')
		}
	}

	function openGNBDrawer() {
		setIsOpenGNBDrawer(!isOpenGNBDrawer)
	}

	function getHeaderContainerClassName() {
		let headerClassNames = []
		if (settings.fixedMode) {
			headerClassNames = headerClassNames.concat(scrollHeight < 88
				? 'absolute'
				: 'fixed')
		} else {
			headerClassNames = headerClassNames.concat(settings.showHeader
				? 'show'
				: 'hide')
		}
		if (settings.isVoucher)
			headerClassNames = headerClassNames.concat('voucher-header')
		return headerClassNames.join(' ')
	}

	function clickLeftArea() {
		if (settings.fixedMode)
			goBack()
		else if (settings.showBack)
			goBack()
		else if (settings.showHamburger)
			setShowSide()
	}

	function clickLocaleActivate(event) {
		event.stopPropagation()
		if (!event.target.parentNode.classList.contains('localeActive')) {
			event.target.parentNode.classList.remove('localeActive')
			// eslint-disable-next-line camelcase
			const { languages_enabled } = JSON.parse(sessionStorage.getItem('mall') || '{}')
			setLanguages(languages_enabled)
		}
		event.target.parentNode.classList.toggle('localeActive')

		return
	}
	function clickLocale(event) {
		event.stopPropagation()
		const lang = event.target.dataset.lang
		localStorage.setItem('lang', lang)
		if (lang !== 'ko') {
			const mall = JSON.parse(sessionStorage.getItem('mall') || '{}')
			window.open('/m/' + mall.mall_id, '_self')
		}
		event.target.parentElement.previousSibling.classList.remove('localeActive')
		return
	}
	function clickRightArea(event) {
		if (settings.showShare) {
			shareOpen('예약 내역을 다른 사람에게 공유하시겠습니까?', '텍스트 복사', '닫기', '공유하기')
			return
		}
		goHome()
	}
	if (!headerExists) {
		return (
			<>
				{settings.showMypage
					? <button className="my-reservation-button" onClick={goMyReservation}>예약내역</button>
					: null}
			</>
		)
	}
	setTimeout(()=> {
		// eslint-disable-next-line camelcase
		const { languages_enabled } = JSON.parse(sessionStorage.getItem('mall') || '{}')
		// eslint-disable-next-line camelcase
		setLanguageEnabled(Boolean(languages_enabled))
	}, 100)


	return (
		<>
			{
				settings.fixedMode ?
					<div className={`header-container ${getHeaderContainerClassName()}`}>
						<div className="left" onClick={clickLeftArea}>
							{settings.showBack ?
								<div> {scrollHeight < 88
									? <WhiteBack className="back white" />
									: <Back className="back" />}
								</div>
								: null}
						</div>
						<div className="center">
						</div>
						<div className="right" onClick={clickRightArea}>
							{settings.showHome
								? <>
									{scrollHeight < 88
										? <WhiteHome className="home" />
										: <Home className="home" />}
								</> :
								null}
						</div>
					</div>
					:
					<>
						{!settings.twoColMode ?
							<div className={`header-container ${getHeaderContainerClassName()}`}>
								<div className="left" onClick={clickLeftArea}>
									{settings.showBack
										? <Back className="back" />
										: null}
									{settings.showHamburger
										? <Hamburger className="hamburger" />
										: null}
								</div>
								<div className="center">
									{
										settings.showLogo
											? <img src={TheYeyakVoucherMainLogo} className="logo" onClick={goHome} alt="logo" />
											: <div>{settings.title}</div>
									}
								</div>
								<div className="right" onClick={clickRightArea}>
									{settings.showClose
										? <Close />
										: null}
									{settings.showHome
										? <Home className="home" />
										: null}
									{settings.showLocale && partnerName === 'tablemanager' && languageEnabled
										? <>
											<Locale className="locale" onClick={clickLocaleActivate}/>
											<ul onClick={clickLocale}>
												{languages.map(({ code, lang })=> <li key={code} data-lang={code}>{lang}</li>) }
											</ul>
										</>
										: null}
									{settings.showShare
										? <Share className="share" />
										: null}
								</div>
							</div>
							:
							<div className={`header-container ${getHeaderContainerClassName()}`}>
								{
									settings.rightOnlyCloseButton ?
										<>
											<div className="left half-width"></div>
											{
												settings.title ?
													<div className="center">{settings.title}</div>
													: <></>
											}
											<div className="right half-width">
												{
													settings.voucherComplete ?
														<Close className="close" onClick={goHome}/> :
														<Home className="close" onClick={goBack}/>
												}
											</div>
										</>
										:
										(
											<>
												<div className="left half-width">
													{
														settings.showOnlyBack || settings.showBack ?
															<Back className="back" onClick={goBack}/>
															: <Menu className="menu" onClick={openGNBDrawer}/>
													}
												</div>
												{
													!settings.voucherMain && settings.title ?
														<div className="center">{settings.title}</div>
														: <></>
												}
												{
													settings.voucherMain && !settings.title ?
														<div className="center">
															<img src={TheYeyakVoucherMainLogo} className="logo" onClick={goHome} alt="logo" />
														</div>
														: <></>
												}
												<div className="right half-width">
													{
														settings.showOnlyBack ?
															<></>
															: <MyPage className="my-page" onClick={goMyPage} />
													}
												</div>
											</>
										)
								}
							</div>
						}
					</>
			}
			{settings.showMypage
				? <button className="my-reservation-button" onClick={goMyReservation}>예약내역</button>
				: null}
			<div className={settings.showHeader
				? 'header-container-placeholder show'
				: 'header-container-placeholder hide'}></div>

			{showSide
				? <SideMenu setShowSide={setShowSide}></SideMenu>
				: null}
		</>
	)
}
